import {  graphql } from "gatsby";
import { Helmet } from "react-helmet";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import * as React from "react";
import Layout from "../pages/components/layout";
import Map from "../pages/components/map";

// markup
const Index = () => {
  
  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  return (
    <Layout>
      <main className="bg-waterford-ocean lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Map</title>
      <meta name="description" content="Map. A map of the digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Map" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/mapview" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

        <title>Waterford AR Trail</title>
        <section className="w-full h-full font-lower">
          <div className="w-full h-24 bg-waterford-ocean"></div>
          <Map />
        </section>
      </main>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;